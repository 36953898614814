@import '@devnomic/marquee/dist';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './nprogress';
@import './buttons';
@import './components';
@import './typography';
@import './fonts';
@import './mobile-nav-menu-animations';
@import './research';
@import './clip-path.css';
@import './search.css';
@import './pif-slider.css';
@import './giveaway-typography.css';
@import './giveaway-colors.css';

@layer base {
  :root {
    --vh-actual: 100vh;
  }

  @supports (height: 100dvh) {
    :root {
      --vh-actual: 100dvh;
    }
  }
}

@layer utilities {
  .h-screen-dynamic {
    height: var(--vh-actual);
  }

  .min-h-screen-dynamic {
    min-height: var(--vh-actual);
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: HCo Whitney SSm, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100%;
  overflow-x: hidden;
}

/* Selects the Next.js router element */
#__next {
  height: 100%;
}

.horizontal-scroller {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.horizontal-scroller::-webkit-scrollbar {
  display: none;
  background-color: transparent;
}

.horizontal-scroller::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.horizontal-scroller::-webkit-scrollbar-track {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background-color: transparent;
}

.slick-slider > .slick-list {
  overflow: visible;
}

.slick-slide {
  transition: opacity 200ms;
}

.slick-slide[aria-hidden='true'] {
  opacity: 40%;
}

.slick-slider .slick-track {
  margin-left: unset;
  margin-right: unset;
}



@media (min-width: 768px) {
  .slick-slider .slick-dots {
    bottom: 24px !important;
  }

  .slick-slider.giveaway .slick-dots {
    bottom: 0 !important;
    @apply !left-1/2
  }

  /* used specifically in GroupTicketsAndPifFooterComponent to modify react-slick carousel dots */
  .slick-slider.tickets-page .slick-dots {
    bottom: 0 !important;
    @apply lg:bottom-6;
  }
}

.slick-slider .slick-dots button:before {
  color: #565453;
  opacity: 1;
}


.slick-slider .slick-dots .slick-active button:before {
  color: white;
}

.express-checkout-slider .slick-center {
  @apply scale-110;
}

.express-checkout-slider .slick-slide {
  width: 200px !important;
  @apply mx-2 !h-[inherit] shrink-0;
}

.grecaptcha-badge {
  visibility: hidden !important;
  pointer-events: none !important;
}

.PhoneInputCountry {
  @apply px-2;
}

.szh-accordion__item-btn {
  width: 100%;
}

.szh-accordion__item-content {
  transition: height 0.25s ease-in-out;
}

.ticket-map-bg {
  background: rgba(59, 60, 60, 0.14);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.3px);
  -webkit-backdrop-filter: blur(10.3px);
  border: 1px solid rgba(59, 60, 60, 0.51);
}

.mapPoint {
  animation: var(--map-point-animation) 3.5s ease-in-out forwards;
  filter: drop-shadow(0 0 20px #262626) brightness(80%);
  will-change: filter;
}

.mapPoint__ticket {
  --map-point-animation: mapPointTicket;
}

@keyframes mapPointTicket {
  0% {
    opacity: 0;
    transform: scale(0.2);
    filter: drop-shadow(0 0 2px rgb(0 0 0 / 0.4));
    fill: white;
  }
  25% {
    filter: drop-shadow(0 0 2px #ff8d00) drop-shadow(0 0 10px #dfdedd) drop-shadow(0 0 1px #dfdedd)
      drop-shadow(5px 5px 20px #dfdedd) drop-shadow(-5px -5px 20px #dfdedd);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
    filter: drop-shadow(0 0 2px #ff8d00) drop-shadow(0 0 10px #dfdedd) drop-shadow(0 0 1px #dfdedd)
      drop-shadow(8px 8px 20px #dfdedd) drop-shadow(-8px -8px 20px #dfdedd);
    fill: #ff8d00;
  }
  75% {
    filter: drop-shadow(0 0 2px #ff8d00) drop-shadow(0 0 10px #dfdedd) drop-shadow(0 0 1px #dfdedd);
  }
  100% {
    transform: scale(1.3);
    opacity: 0.1;
  }
}

.child-width-full > * {
  @apply w-full;
}

.ticket-qr svg {
  @apply h-[110px] w-[110px] md:h-[125px] md:w-[125px];
}

.scrollbar-none {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-none::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ticket-mobile-footer .splide__track {
  overflow: visible !important;
}

.ticket-mobile-footer .splide__slide {
  overflow: visible !important;
}

.tickets-carousel .splide__track {
  overflow: visible !important;
}

.tickets-page .splide__pagination__page {
  width: 4px !important;
  height: 4px !important;
}

.giveaway .splide__pagination__page {
  width: 4px !important;
  height: 4px !important;
  @apply !bg-core-gray-400
}

.giveaway .splide__pagination__page.is-active {
  @apply !bg-core-gray-700
}


.embla {
  overflow: hidden;
}


.embla__container {
  display: flex;
  backface-visibility: hidden;
  touch-action: pan-y pinch-zoom;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  min-width: 0;
}
.embla__slide {
  flex: 0 0 72px;
  min-width: 0;
  margin-right: 8px;
  user-select: none;
  position: relative;
  min-height: 100%;
}

@media (min-width: 768px) {
  .embla__exclusive_scenes {
    max-width: 72rem;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 2rem;
    --slide-size: 80%;
  }

  .embla__viewport_exclusive_scenes {
    overflow: hidden;
  }

  .embla__container_exclusive_scenes {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
  }

  .embla__slide_exclusive_scenes {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }

  .embla__dot:after {
    --detail-medium-contrast: rgb(234, 234, 234);
    --text-body: rgb(54, 49, 61);
    box-shadow: inset 0 0 0 0.2rem var(--text-body);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
    margin-right: 1rem;
  }

  .embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  }

  .transparent-carousel-gradient {
    -webkit-mask-image: linear-gradient(to right, transparent, black 5%, black 95%, transparent);
    mask-image: linear-gradient(to right, transparent, black 5%, black 95%, transparent);
  }
}

.scale-effect {
  transition: transform 1200ms ease-in-out;
}

.scale-effect:hover {
  transform: scale(1.05); /* Scales up to 105% of the original size */
}

@keyframes slideInFromLeft {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.slide-in-left {
  animation: slideInFromLeft 1s ease-in-out forwards;
}

.fade-marquee {
  mask-image: linear-gradient(transparent 0%, rgb(0, 0, 0) 10%, rgb(0, 0, 0) 50%, transparent 100%) !important;
}

