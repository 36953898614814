
@layer components {
    .pill-badge {
        @apply w-fit flex items-center justify-center rounded-full gap-1;
    }

    .pill-badge-sm {
        @apply py-1 px-3 h-[24px] photon-caption-sm font-medium;
    }

    .pill-badge-md {
        @apply py-1 px-3 h-[28px] photon-caption-md font-medium;
    }


    .lozenge {
        @apply w-fit flex items-center justify-center
    }

    .lozenge-sm {
        @apply photon-eyebrow-xs font-semibold h-[18px] py-0.5 px-2 rounded
    }

    .lozenge-md {
        @apply photon-eyebrow-sm font-semibold h-5 py-1 px-2.5 rounded-[5px]
    }

    .lozenge-lg {
        @apply photon-eyebrow-md font-semibold h-[22px] py-1 px-3 rounded-md
    }
}



